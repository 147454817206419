@tailwind base;
@tailwind components;
@tailwind utilities;

.datepicker-container {
  position: relative; /* Ensure it's correctly positioned within its parent */
}

.react-datepicker {
  width: auto;
  min-width: 250px; /* Adjust as needed */
}

.parent-container {
  overflow: visible; /* Prevent cutting off the Datepicker */
}


@layer components {
  /*-------------------------------------
  Container CSS
--------------------------------------*/
  .container-xs {
    @apply w-full mx-auto max-w-[91.25rem];
  }

  .container {
    @apply w-full mr-auto ml-auto;
  }
}
