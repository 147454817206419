.footer.icon{
    width: 45px;
    height: 45px;
}

.btn-round {
    height: 54px;
    width: 54px;
    line-height: 50px;
    border-radius: 54px;
}